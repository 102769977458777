import { BuyProLoading } from '@experiences/ecommerce';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import type { PropsWithChildren } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    useLocation,
    useMatch,
} from 'react-router-dom';

import ProtectedRoute from '../../auth/ProtectedRoute';
import { ShellEventNames } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import { useAdminNavigationRoute } from '../../common/hooks/useAdminNavigationRoute';
import useIsNoShowLeftNav from '../../common/hooks/useIsNoShowLeftNav';
import useIsOnPrem from '../../common/hooks/useIsOnPrem';
import useProductName from '../../common/hooks/useProductName';
import useProductNameSuffix from '../../common/hooks/useProductNameSuffix';
import { markAnnouncementRead } from '../../store/action/AnnouncementAction';
import {
    announcementsList,
    unReadAnnouncementCount,
} from '../../store/selectors';
import {
    useIsSettingsRouteMatched,
    useShouldHideTenantShell,
} from '../../util/RouteUtil';
import OrganizationSwitcherDialogComponent from '../authentication/organizationSwitcher/OrganizationSwitcherDialogComponent';
import UiLicenseExpiredBanner from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiTrialPerSkuLicenseInGracePeriodBanners from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import { EcommercePaymentFailedBannerComponent }
    from '../ecommerce/subcomponents/EcommercePaymentFailedBanner/EcommercePaymentFailedBannerComponent';
import { Notifications } from './Notifications';
import TenantShell from './TenantShell';

const useStyles = makeStyles(theme =>
    createStyles({
        leftRail: { height: '100%' },
        content: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            backgroundColor: theme.palette.semantic.colorBackground,
        },
        appContent: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            overflow: 'auto',
            boxSizing: 'border-box',
            height: '100%',
        },
        popoverDefaults: {
            width: '339px',
            height: 'auto',
            border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            boxShadow: '0px 9px 46px rgba(0, 0, 0, 0.12)',
        },
        version: {
            color: theme.palette.semantic.colorForegroundDisable,
            paddingBottom: '12px',
        },
        notificationsIcon: {
            height: '48px',
            display: 'flex',
            alignSelf: 'center',
        },
        copyRightSlot: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: '-27px',
        },
        copyRightText: { color: theme.palette.semantic.colorForegroundDeEmp },
        hidden: { display: 'none' },
        adminToggle: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px',
        },
        adminToggleText: {
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
    }),
);

const PrivateShell: React.FC<{ children?: React.ReactNode }> = props => {
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const {
        productName, showTrademark,
    } = useProductName();
    const { formatMessage: translate } = useIntl();
    const isNoLeftNavRoute = useIsNoShowLeftNav();
    const dispatch = useDispatch();
    const location = useLocation();
    const getRoute = useRouteResolver();
    const theme = useTheme();
    const productNameSuffix = useProductNameSuffix();

    const [ notificationsAnchorEl, setNotificationsAnchorEl ] = useState<any>(null);

    const apShell = document.getElementById('apollo-shell') as HTMLApShellElement;
    const isSettingsRoute = useIsSettingsRouteMatched();
    const shouldHideTenantShell = useShouldHideTenantShell();
    const isPortalHome = useMatch(RouteNames.Home);
    const isOrderLoadingPage = useMatch(BuyProLoading);
    const announcements = useSelector(announcementsList);
    const unreadAnnouncementsCount = useSelector(unReadAnnouncementCount);
    const isOnPrem = useIsOnPrem();
    const isNotificationsPage = useMatch({
        path: RouteNames.Notifications,
        caseSensitive: true,
    });
    const adminNavigationRoute = useAdminNavigationRoute();

    const EnableAiChatFeature = useFeatureFlagValue(Features.EnableAiChatFeature.name);

    const enableGovernanceModuleFederation = useFeatureFlagValue(Features.EnableGovernanceModuleFederation.name);
    const EnableLeftRailExperiment = useFeatureFlagValue(Features.EnableLeftRailExperiment.name);
    const EnableEcommerceIntegration = useFeatureFlagValue(Features.EnableEcommerceIntegration.name);

    const isResourceCenter = useMatch(RouteNames.ResourceCenter);

    const onLeftNavItemChangeCallback = useCallback(
        (e: any) => {
            const requestedRoute = enableGovernanceModuleFederation
                ? e.detail.serviceid as 'admin' | 'home' | 'governance'
                : e.detail.serviceid as 'admin' | 'home';

            if (requestedRoute === 'admin') {
                navigate(adminNavigationRoute);
            } else if (requestedRoute === 'home') {
                navigate(getRoute(RouteNames.Home));
            } else if (enableGovernanceModuleFederation && requestedRoute === 'governance') {
                navigate(getRoute(routePaths.adminAiTrustLayer));
            }
        },
        [ navigate, adminNavigationRoute, getRoute, enableGovernanceModuleFederation ],
    );

    const isPageWithNoDisplayedBanner = useMemo(
        () => isOrderLoadingPage,
        [ isOrderLoadingPage ],
    );

    const openNotificationsFlyout = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setNotificationsAnchorEl(event.currentTarget);
    }, []);

    const closeNotificationsFlyout = useCallback(() => {
        if (announcements?.length > 0) {
            dispatch(markAnnouncementRead());
        }
        setNotificationsAnchorEl(null);
    }, [ announcements?.length, dispatch ]);

    const showNotificationsBell = useMemo(() => announcements.length > 0, [ announcements?.length ]);

    const chatMessageReceivedListener = useCallback((event: any) => {
        const {
            source, content, hidden, postMessage,
        } = event.detail;

        if (!hidden && postMessage) {
            postMessage(
                source,
                content,
            );
        }
    }, []);

    const logoutListener = useCallback(() => {
        navigate('/portal_/logout');
    }, [ navigate ]);

    const portalShellLoadedListener = useCallback(() => {
        window.PortalShell?.Chat?.initialize({
            context: `You are an expert on UiPath Automation Cloud home and admin sections.`,
            serviceAvatarUrl: 'https://platform-cdn.uipath.com/portal/portal/images/flyingRobot.png',
        });
    }, []);

    useEffect(() => {
        if (EnableAiChatFeature) {
            if (window.PortalShell?.Chat) {
                portalShellLoadedListener();
            } else {
                document.addEventListener('portalShellLoaded', portalShellLoadedListener);
            }
        }

        document.addEventListener('chatMessageReceived', chatMessageReceivedListener);
        document.addEventListener('logout', logoutListener);

        return () => {
            document.removeEventListener('chatMessageReceived', chatMessageReceivedListener);
            document.removeEventListener('logout', logoutListener);
            EnableAiChatFeature && document.removeEventListener('portalShellLoaded', portalShellLoadedListener);
        };
    }, [
        productName,
        EnableAiChatFeature,
        chatMessageReceivedListener,
        logoutListener,
        portalShellLoadedListener,
    ]);

    const notificationsBellAndPopover = useMemo(() => (
        <>
            {showNotificationsBell && (
                <IconButton
                    color="default"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    data-cy="App_Bar_Notifications_Button"
                    onClick={openNotificationsFlyout}
                    className={classes.notificationsIcon}
                >
                    <Badge
                        badgeContent={unreadAnnouncementsCount}
                        color="error">
                        <NotificationsOutlinedIcon style={{ transform: 'scale(1.25)' }} />
                    </Badge>
                </IconButton>
            )}
            <Popover
                anchorEl={notificationsAnchorEl}
                open={Boolean(notificationsAnchorEl)}
                onClose={closeNotificationsFlyout}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{ className: classes.popoverDefaults }}
                transitionDuration={200}
            >
                <Notifications announcements={announcements} />
            </Popover>
        </>
    ), [
        announcements,
        classes.notificationsIcon,
        classes.popoverDefaults,
        closeNotificationsFlyout,
        notificationsAnchorEl,
        openNotificationsFlyout,
        showNotificationsBell,
        unreadAnnouncementsCount,
    ]);

    const mainContent = useMemo(() => (
        <>
            <div role="banner">
                {!isPortalHome && !isPageWithNoDisplayedBanner
                            && <>
                                <UiLicenseInGracePeriodBanner />
                                <UiLicenseExpiredBanner />
                                <UiTrialPerSkuLicenseInGracePeriodBanners />
                            </>}

                {!isPageWithNoDisplayedBanner && EnableEcommerceIntegration && <EcommercePaymentFailedBannerComponent />}
            </div>
            <div
                className={classes.appContent}
                id="portal-root"
                role="main"
            >
                {isSettingsRoute && !shouldHideTenantShell && <TenantShell />}
                {props.children}
            </div>
        </>
    ), [
        isPortalHome,
        isPageWithNoDisplayedBanner,
        EnableEcommerceIntegration,
        classes.appContent,
        isSettingsRoute,
        shouldHideTenantShell,
        props.children,
    ]);

    const apShellContents = useMemo(() => (
        <>
            {notificationsBellAndPopover}

            {mainContent}
        </>
    ), [
        mainContent,
        notificationsBellAndPopover,
    ]);

    const active = useMemo(() => {
        if (location.pathname?.toLowerCase() === getRoute(RouteNames.Home).toLowerCase()) {
            return 'home';
        }
        if (enableGovernanceModuleFederation && location.pathname?.toLowerCase()
            === getRoute(routePaths.adminAiTrustLayer).toLowerCase()) {
            return 'governance';
        }
        if (isSettingsRoute) {
            return 'admin';
        }
        return '';
    }, [ location, isSettingsRoute, getRoute, enableGovernanceModuleFederation ]);

    useEffect(() => {
        apShell.addEventListener(ShellEventNames.SERVICE_CLICKED, onLeftNavItemChangeCallback);
        return () => apShell.removeEventListener(ShellEventNames.SERVICE_CLICKED, onLeftNavItemChangeCallback);
    }, [ apShell, onLeftNavItemChangeCallback ]);

    const onLogoClicked = useCallback(() => {
        navigate(getRoute(RouteNames.OrganizationAdminHome));
    }, [ navigate, getRoute ]);

    useEffect(() => {
        apShell.addEventListener(ShellEventNames.LOGO_CLICKED, onLogoClicked);
        return () => apShell.removeEventListener(ShellEventNames.LOGO_CLICKED, onLogoClicked);
    }, [ apShell, onLogoClicked ]);

    const isFocusMode = !isPortalHome || isNoLeftNavRoute;
    const isHidingTenantPicker = (isOnPrem && !isPortalHome) || isSettingsRoute || !!isNotificationsPage;
    const feature = isResourceCenter ? translate({ id: 'CLIENT_RESOURCE_CENTER' }) : undefined;
    apShell.setAttribute('product-name', productName);
    apShell.setAttribute('show-product-trademark', showTrademark.toString());
    apShell.setAttribute('focus-mode', EnableLeftRailExperiment ? 'false' : isFocusMode.toString());
    apShell.setAttribute('data-cy', 'ap-shell');
    apShell.setAttribute('active', active);
    apShell.setAttribute('hide-tenant-picker', isHidingTenantPicker.toString());
    apShell.setAttribute('emit-event-and-prevent-navigation-services',
        enableGovernanceModuleFederation ? 'admin,help,governance,home' : 'admin,help,home');
    apShell.setAttribute('disable-product-click', (!isSettingsRoute).toString());

    if (feature) {
        apShell.setAttribute('feature', feature.toString());
    } else {
        apShell.removeAttribute('feature');
    }

    return createPortal(
        <>
            <div slot='slot-start'>
                <UiText
                    style={{
                        fontSize: Tokens.FontFamily.FontLSize,
                        fontWeight: Tokens.FontFamily.FontLBoldWeight,
                        lineHeight: Tokens.FontFamily.FontLLineHeight,
                        letterSpacing: '-0.033em',
                        fontFamily: Tokens.FontFamily.FontTitle,
                        color: theme.palette.semantic.colorForegroundEmp,
                        overflow: 'visible',
                        marginTop: Tokens.Padding.PadXs,
                    }}>
                    {productNameSuffix}
                </UiText>
            </div>
            <OrganizationSwitcherDialogComponent />
            {apShellContents}
        </>,
        apShell
    );
};

export default (props: PropsWithChildren<{}>) => (
    <ProtectedRoute>
        <PrivateShell {...props} />
    </ProtectedRoute>
);
