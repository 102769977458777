import type { IOrganization } from '@experiences/interfaces';

import type {
    IAccountSettingsDto,
    IUpdateOrganizationResponse,
} from '../../component/organizationsettings/subcomponents/types/settings';
import type { IServiceMetadata } from '../../component/tenants/interfaces/service';
import {
    get,
    patch,
    post,
} from '../utility/Requests';

const organizationManagementUri = '/api/organization';

export function getOrganizationData() {
    return get<IOrganization>(`${organizationManagementUri}/organizationInfo`);
}

export function updateOrganization(payload: IAccountSettingsDto) {
    if (process.buildConfigs.orgNameIsOrgLogicalName) {
        payload.logicalName = payload.companyName;
    }
    return patch<IUpdateOrganizationResponse>(organizationManagementUri, { body: { ...payload } });
}

export function deleteOrganization(deletionQuestion: string) {
    return post<{ status: number; data: any }>(`${organizationManagementUri}/deleteOrganization`, { body: { deletionQuestion } });
}

export function getSupportedRegions({
    organizationGuid, accountName,
}: { url: string; organizationGuid: string; accountName: string }) {
    return get<IServiceMetadata[]>(`${organizationManagementUri}/availableservices`, {
        urlParams: {
            organizationGuid,
            accountName,
        },
    });
}
