import type { IPagination } from '@experiences/interfaces';

import type { IUserCISResponse } from '../../component/users/interfaces/userLicense';
import { cleanUserCISResponse } from '../mappers/UserPartitionServiceMapper';
import { get } from '../utility/Requests';

export const userPartitionUrl = `${process.buildConfigs.identityApiBaseRoute}/UserPartition`;

export default async function getUsersInPartition({
    pagination, partitionGlobalId,
}: {
    pagination: IPagination;
    partitionGlobalId: string;
}): Promise<IUserCISResponse> {
    const response = await get<IUserCISResponse>(`${userPartitionUrl}/users/${partitionGlobalId}`, { pagination });
    return cleanUserCISResponse(response);
}

export async function getUsersInPartitionWithLicenses({
    pagination, partitionGlobalId,
}: {
    pagination: IPagination;
    partitionGlobalId: string;
}): Promise<IUserCISResponse> {
    const response = await get<IUserCISResponse>(`${userPartitionUrl}/licenses`, {
        urlParams: { partitionGlobalId },
        pagination,
    });
    return cleanUserCISResponse(response);
}
