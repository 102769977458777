import type { IServiceUsageResponse } from '@experiences/interfaces';
import { useApolloTheme } from '@experiences/theme';
import { roundTo2Decimals } from '@experiences/util';
import { useTheme } from '@mui/material';
import { Colors } from '@uipath/apollo-core';
import type { LegendRow } from '@uipath/apollo-lab';
import { Chart } from '@uipath/apollo-lab/react';
import type { ApolloThemeType } from '@uipath/portal-shell-util';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { useConsumableToServicesMap } from '../../../common/constants/Constant';
import {
    getConsumptionData,
    licenseUsageUrl,
} from '../../../services/licensing/LicenseUsageService';
import { customLegendRenderer } from '../../common/ApolloLab/CustomLegendRenderer';
import { addXAxisFooter } from '../../common/UiBarChart/BarChartContentHelper';
import { UiChartLoading } from '../helperComponents/UiConsumableCard/UiChartLoading';

const indexToColorMap: (themeId: ApolloThemeType) => { [key: number]: string } = (themeId) => {
    const isLight = themeId.includes('light');

    return {
        0: isLight ? Colors.ColorBlueSecondary500 : Colors.ColorBlueSecondary300,
        1: isLight ? Colors.ColorBrandAccessoryPurple : Colors.ColorPurple200,
        2: isLight ? Colors.ColorCodeNumericLightHc : Colors.ColorCodeNumericDarkHc,
        3: isLight ? Colors.ColorOrange500 : Colors.ColorOrange200,
        4: isLight ? Colors.ColorRed500 : Colors.ColorRed300,
        5: isLight ? Colors.ColorGreen500 : Colors.ColorGreen300,
        6: isLight ? Colors.ColorGray500 : Colors.ColorGray300,
    };
};

const minNumberOfPointsInGraph = 5;
export const DailyConsumptionGraph: React.FC<{
    startDate: number;
    endDate: number;
    consumableCode: string;
    services?: string[];
    tenantId?: string;
    chartDescription?: string;
}> = ({
    startDate,
    endDate,
    consumableCode,
    services,
    tenantId,
    chartDescription,
}) => {
    const { formatMessage: translate } = useIntl();
    const { themeId } = useApolloTheme();
    const theme = useTheme();
    const consumableTOServicesMap = useConsumableToServicesMap();
    const { data: consumptionData } = useSWR(
        {
            url: `${licenseUsageUrl}/services`,
            startDate,
            endDate,
            consumableCode,
            services: services ?? consumableTOServicesMap[consumableCode],
            aggregationPeriod: 'day',
            tenantId,
        },
        getConsumptionData,
    );

    const strToDate = useCallback((date: string) => {
        const [ day, month, year ] = date.split('/');
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }, []);

    const numberOfDaysBetweenCalendarDates = useCallback((date1: Date, date2: Date) => {
        const dateOnly1 = new Date(date1.getUTCFullYear(), date1.getUTCMonth(), date1. getUTCDate());
        const dateOnly2 = new Date(date2.getUTCFullYear(), date2.getUTCMonth(), date2. getUTCDate());
        const diffInMilliseconds = dateOnly2.getTime() - dateOnly1.getTime();
        return Math.ceil(diffInMilliseconds / (1000 * 3600 * 24));
    }, []);

    const dateToGraphIndex = useCallback((date: string) => {
        const beginningOfDayInUtc = new Date(strToDate(date).getTime() - strToDate(date).getTimezoneOffset() * 60 * 1000);

        return numberOfDaysBetweenCalendarDates(new Date(startDate * 1000), beginningOfDayInUtc);
    },
    [ numberOfDaysBetweenCalendarDates, startDate, strToDate ]);

    const numberOfDaysToShowInGraph = useMemo(() => {
        const currentDateIndex = numberOfDaysBetweenCalendarDates(new Date(startDate * 1000), new Date()) + 1;
        return Math.max(currentDateIndex, minNumberOfPointsInGraph);
    }
    , [ numberOfDaysBetweenCalendarDates, startDate ]);

    const consumptionDataWithDefaults: Array<{ service: string; data: number[]; colorIndex: number }> = useMemo(() => {
        if (!consumptionData) {
            return [];
        }

        return consumptionData.servicesUsage.map((serviceUsage: IServiceUsageResponse, index) => {
            const consumptionPerDay: number[] = new Array(numberOfDaysToShowInGraph).fill(0);
            serviceUsage.usages.forEach((usage) => {
                const graphIndex = dateToGraphIndex(usage.usageAt);
                if (index < numberOfDaysToShowInGraph) {
                    consumptionPerDay[graphIndex] = roundTo2Decimals(usage.consumedQuantity);
                }
            });

            return {
                service: serviceUsage.serviceName,
                data: consumptionPerDay,
                colorIndex: index,
            };
        }
        );
    }, [ consumptionData, dateToGraphIndex, numberOfDaysToShowInGraph ]);

    const getServiceName = useCallback((index: number) =>
        translate({ id: `CLIENT_CONSUMABLE_GRAPH_${consumptionDataWithDefaults[index].service}` })
    , [ consumptionDataWithDefaults, translate ]);

    const labels = useMemo(() => {
        const labelsToReturn: string[] = [];
        for (let i = 0; i < numberOfDaysToShowInGraph; i++) {
            const date = new Date(startDate * 1000 + i * 3600 * 24 * 1000);
            labelsToReturn.push(`${date.getUTCDate()}`);
        }
        return labelsToReturn;
    }, [ numberOfDaysToShowInGraph, startDate ]);

    if (!consumptionData?.servicesUsage) {
        return <UiChartLoading
            width={150}
            height={36} />;
    }
    return <Chart
        className={`daily-consumption-graph-${consumableCode}`}
        data={consumptionDataWithDefaults.map((consumption) => ({
            data: consumption.data,
            renderer: 'line',
            color: indexToColorMap(themeId)[consumption.colorIndex],
            title: translate({ id: `CLIENT_CONSUMABLE_GRAPH_${consumption.service}` }),
            isCompareData: false,
        })
        )}
        labels={labels}
        legendProperties={{ hasLegend: true }}
        alt={`${translate({ id: 'CLIENT_CONSUMABLE_GRAPH_SHORT_NAME' })} - ${chartDescription}`}
        chartInstructions={`${translate({ id: 'CHART_INSTRUCTIONS_NAVIGATE_WITH_KEYBOARD' })}`}
        tooltipSchemaBuilder={(values) => {
            const dataValues: LegendRow[] = values.map((value, i) => ({
                cells: [
                    {
                        text: `${getServiceName(i)} ${value.value.y}`,
                        color: value.color,
                        content: customLegendRenderer({
                            borderColor: theme.palette.semantic.colorBackground,
                            color: value.color,
                            text: `${getServiceName(i)} ${value.value.y}`,
                        }),
                    },
                ],
            }));
            addXAxisFooter(dataValues, values);
            return dataValues;
        }}
    />;
};
