import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import useCheckAuthTypes from '../../auth/hooks/CheckAuthType';
import * as RouteNames from '../../common/constants/RouteNames';
import { useGetSetting } from '../../common/hooks/useGetSetting';
import useIsBasicAuthEnabled from '../../common/hooks/useIsBasicAuthEnabled';
import { isHostModeSelector } from '../../store/selectors';
import { UiPanel } from '../common/UiPanel/UiPanel';
import NotificationSettingsComponent from '../notificationSettings/NotificationSettingsComponent';
import PersonalAccessTokenSettingsComponent from './PersonalAccessTokenSettingsComponent';
import PrivacyAndSecuritySettingsComponent from './PrivacyAndSecuritySettingsComponent.default';
import ProfileAccessibilitySettingsComponent from './ProfileAccessibilitySettingsComponent';
import ProfileGeneralSettingsComponent from './ProfileGeneralSettingsComponent';
import ProfileThemeSettingsComponent from './themeSettings/ProfileThemeSettingsComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        closeIcon: { cursor: 'pointer' },
        content: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        panelHeader: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '20px',
        },
        tab: {
            textTransform: 'none',
            minHeight: '0px',
            textAlign: 'left',
            lineHeight: '20px',
            padding: '2px 8px 2px 8px!important',
            margin: '4px',
            fontWeight: 400,
        },
        tabPanelWrapper: {
            display: 'flex',
            flexDirection: 'column',
            padding: '6px',
            width: '100%',
        },
        tabs: {
            width: '210px',
            '& .MuiTabs-flexContainer, .MuiTab-wrapper, .MuiTab-root': { alignItems: 'flex-start' },
            '& .MuiTabs-indicator': {
                left: '0px',
                width: '4px',
                backgroundColor: theme.palette.semantic.colorSelectionIndicator,
            },
            '& .Mui-selected': {
                fontWeight: 600,
                color: theme.palette.semantic.colorForeground,
            },
        },
    }),
);

enum Setting {
    ReferenceTokensEnabled = 'ReferenceTokensEnabled',
}

enum ProfileTab {
    GeneralSettings = 'GeneralSettings',
    ThemeSettings = 'ThemeSettings',
    AccessibilitySettings = 'AccessibilitySettings',
    NotificationSettings = 'NotificationSettings',
    PrivacyAndSecuritySettings = 'PrivacyAndSecuritySettings',
    PersonalAccessTokenSettings = 'PersonalAccessTokenSettings',
}

const TabPanel: React.FC<{ value: string }> = ({ value }) => {
    switch (value) {
        case ProfileTab.GeneralSettings:
            return <ProfileGeneralSettingsComponent />;
        case ProfileTab.ThemeSettings:
            return <ProfileThemeSettingsComponent />;
        case ProfileTab.AccessibilitySettings:
            return <ProfileAccessibilitySettingsComponent />;
        case ProfileTab.NotificationSettings:
            return <NotificationSettingsComponent />;
        case ProfileTab.PrivacyAndSecuritySettings:
            return <PrivacyAndSecuritySettingsComponent />;
        case ProfileTab.PersonalAccessTokenSettings:
            return <PersonalAccessTokenSettingsComponent />;
        default:
            return null;
    }
};

const ProfilePageComponent: React.FC = () => {
    const EnableNotificationSettings = useFeatureFlagValue(Features.EnableNotificationSettings.name);
    const EnablePrivacyAndSecuritySettings = !useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableThemeSettings = useFeatureFlagValue(Features.EnableHighContrastLightTheme.name);
    const EnableAccessibilitySettings = useFeatureFlagValue(Features.EnableAlertDuration.name);
    const EnableReferenceTokens = useFeatureFlagValue(Features.EnableReferenceTokens.name);

    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const isPrivacyAndSecuritySettings = useMatch(RouteNames.PrivacyAndSecuritySettings);
    const isNotificationSettings = useMatch({
        path: RouteNames.NotificationSettings,
        end: false,
    });
    const isPersonalAccessTokenSettings = useMatch({
        path: RouteNames.PersonalAccessToken,
        end: false,
    });
    const isThemeSettings = useMatch(RouteNames.ThemeSettings);
    const isAccessibilitySettings = useMatch(RouteNames.AccessibilitySettings);
    const isBasicAuthEnabled = useIsBasicAuthEnabled();
    const {
        checkTokenAuthIsAzure, checkTokenAuthIsSAML,
    } = useCheckAuthTypes();
    const [ tabValue, setTabValue ] = useState<ProfileTab>(
        (isNotificationSettings && ProfileTab.NotificationSettings) ||
        (isPrivacyAndSecuritySettings && ProfileTab.PrivacyAndSecuritySettings) ||
        (isPersonalAccessTokenSettings && ProfileTab.PersonalAccessTokenSettings) ||
        (isThemeSettings && ProfileTab.ThemeSettings) ||
        (isAccessibilitySettings && ProfileTab.AccessibilitySettings) ||
        ProfileTab.GeneralSettings,
    );

    const isHostMode = useSelector(isHostModeSelector);

    const { data: settingsData } = useGetSetting([ Setting.ReferenceTokensEnabled ]);
    const isReferenceTokensEnabled = useMemo(() =>
        settingsData?.find(setting => setting.key === Setting.ReferenceTokensEnabled)?.value.toLowerCase() === 'true',
    [ settingsData ]);
    const isDirectoryUser = checkTokenAuthIsAzure || checkTokenAuthIsSAML;

    const handleTabChange = useCallback((_: any, newValue: string) => setTabValue(newValue as ProfileTab), [ setTabValue ]);

    return (
        <UiPanel header={{
            title: translate({ id: 'CLIENT_PREFERENCES' }),
            className: classes.panelHeader,
        }}>
            <div className={classes.content}>
                <Tabs
                    className={classes.tabs}
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    orientation="vertical"
                >
                    <Tab
                        data-cy="profile-page-general-settings-tab"
                        className={classes.tab}
                        value={ProfileTab.GeneralSettings}
                        label={translate({ id: 'CLIENT_GENERAL' })}
                        onClick={() => navigate(getRoute(RouteNames.Profile))}
                    />
                    {EnableThemeSettings && (<Tab
                        data-cy="profile-page-theme-settings-tab"
                        className={classes.tab}
                        value={ProfileTab.ThemeSettings}
                        label={translate({ id: 'CLIENT_THEME' })}
                        onClick={() => navigate(getRoute(RouteNames.ThemeSettings))}
                    />)}

                    {EnableAccessibilitySettings && (
                        <Tab
                            data-cy="profile-page-accessibility-settings-tab"
                            className={classes.tab}
                            value={ProfileTab.AccessibilitySettings}
                            label={translate({ id: 'CLIENT_ACCESSIBILITY' })}
                            onClick={() => navigate(getRoute(RouteNames.AccessibilitySettings))}
                        />
                    )}

                    {EnablePrivacyAndSecuritySettings &&
                        (!process.buildConfigs.enableUserProfileChangePassword || isBasicAuthEnabled) && (
                        <Tab
                            data-cy="profile-page-privacy-and-security-settings-tab"
                            className={classes.tab}
                            value={ProfileTab.PrivacyAndSecuritySettings}
                            label={translate({ id: 'CLIENT_PRIVACY_AND_SECURITY' })}
                            onClick={() => navigate(getRoute(RouteNames.PrivacyAndSecuritySettings))}
                        />
                    )}
                    {EnableNotificationSettings && !isHostMode && (
                        <Tab
                            data-cy="profile-page-notification-settings-tab"
                            className={classes.tab}
                            value={ProfileTab.NotificationSettings}
                            label={translate({ id: 'CLIENT_NOTIFICATIONS' })}
                            onClick={() => navigate(getRoute(RouteNames.NotificationSettings))}
                        />
                    )}
                    {EnableReferenceTokens && !isHostMode && isReferenceTokensEnabled && !isDirectoryUser && (
                        <Tab
                            data-cy="profile-page-personal-access-token-settings-tab"
                            className={classes.tab}
                            value={ProfileTab.PersonalAccessTokenSettings}
                            label={translate({ id: 'CLIENT_PERSONAL_ACCESS_TOKEN' })}
                            onClick={() => navigate(getRoute(RouteNames.PersonalAccessToken))}
                        />
                    )}
                </Tabs>
                <div className={classes.tabPanelWrapper}>
                    <TabPanel value={tabValue} />
                </div>
            </div>
        </UiPanel>
    );
};

export default () => (
    <>
        <ProfilePageComponent />
        <UiSuspensefulOutlet />
    </>
);
