import type { LanguageCulture } from '@experiences/locales';

import type { ICMSDataContainer } from '../common/interfaces/resource';
import { get } from './utility/Requests';

export const resourceCenterCMSUrl = '/api/content/resourceCenter';

export function getContentfulResource({
    language, bypassCache,
}: { url: string; language: LanguageCulture; bypassCache: string }) {
    return get<ICMSDataContainer>(resourceCenterCMSUrl, {
        urlParams: {
            language,
            bypassCache,
        },
    });
}
