import { setUserLanguage } from '@uipath/portal-shell-util';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useUserInfo from '../../auth/hooks/UserInfo';
import {
    accountGlobalId,
    userGlobalId,
} from '../../store/selectors';
import { getOrganizationStandardUrl } from '../../util/UrlUtil';

const useSetUserLanguage = () => {
    const { token } = useUserInfo();
    const partitionGlobalId = useSelector(accountGlobalId);
    const userId = useSelector(userGlobalId);
    return useCallback(async (language: string) => {
        await setUserLanguage(language, getOrganizationStandardUrl(), token, partitionGlobalId, userId);
    }, [ partitionGlobalId, token, userId ]);
};

export default useSetUserLanguage;
