import { RouteProvider } from '@experiences/util';
import React from 'react';
import { type RouteObject } from 'react-router-dom';

import AuthCallback from '../../auth/AuthCallback';
import AuthError from '../../auth/AuthError';
import Logout from '../../auth/Logout';
import { SessionExpiry } from '../../auth/SessionExpiry';
import * as RouteNames from '../../common/constants/RouteNames';
import AccountAdmin from '../../component/authentication/AccountAdmin.default';
import GenericError from '../../component/authentication/GenericError';
import InvalidUrl from '../../component/authentication/InvalidUrl';
import RedirectToService from '../../component/authentication/RedirectToService';
import Unregistered from '../../component/authentication/Unregistered';
import { Diagnostics } from '../../component/diagnostics/Diagnostics';
import AccessPolicyError from '../../component/securitySettings/subcomponents/AccessPolicyError';
import RestrictedPolicyError from '../../component/securitySettings/subcomponents/RestrictedPolicyError';
import OrchestratorRouting from '../../component/tenants/subcomponents/OrchestratorRouting';
import RequestUserTokenCallback from '../../component/tenants/subcomponents/token/RequestUserTokenCallback';
import { TestConnection } from '../../component/users/subcomponents/TestConnection';
import App from '../App';
import OriginRouteComponent from '../OriginRouteComponent';
import ShellRouteWrapper from '../ShellRouteWrapper';

const routes: RouteObject[] = [
    {
        element: <App />,
        children: [
            {
                path: '/genericerror',
                element: <GenericError />,
            },
            {
                path: '/portal_/genericerror',
                element: <GenericError />,
            },
            {
                path: '/',
                element: <OriginRouteComponent />,
            },
            {
                path: '/portal_',
                element: <OriginRouteComponent />,
            },
            {
                path: '/portal_/cloudrpa',
                element: <OriginRouteComponent />,
            },
            {
                path: '/:accountName',
                element: <OriginRouteComponent />,
            },
            {
                path: '/portal_/logout',
                element: <Logout />,
            },
            {
                path: '/portal_/testconnection',
                element: <TestConnection />,
            },
            {
                path: '/portal_/restricted-policy',
                element: <RestrictedPolicyError />,
            },
            {
                path: '/portal_/autoroute/:accountName/:tenantName',
                element: <OrchestratorRouting />,
            },
            {
                path: '/portal_/unregistered',
                element: <Unregistered />,
            },
            {
                path: '/portal_/requestusertokencallback',
                element: <RequestUserTokenCallback />,
            },
            {
                path: '/portal_/sessionexpired',
                element: <SessionExpiry />,
            },
            {
                path: '/portal_/autherror',
                element: <AuthError />,
            },
            {
                path: '/portal_/accesserror',
                element: <AccessPolicyError />,
            },
            {
                path: '/:accountName/portal_/diagnostics',
                element: <Diagnostics />,
            },
            {
                path: RouteNames.Root,
                element: <AccountAdmin />,
            },
            {
                path: RouteNames.LoginSuccess,
                element: <AuthCallback />,
            },
            {
                path: RouteNames.CloudRPA,
                element: <AccountAdmin />,
            },
            {
                path: RouteNames.RedirectToService,
                element: <RedirectToService />,
            },
            {
                path: `${RouteNames.PortalPrefix}/*`,
                element: <RouteProvider>
                    <ShellRouteWrapper />
                </RouteProvider>,
            },
            {
                path: '*',
                element: <InvalidUrl />,
            },
        ],
    },
];

export default routes;
