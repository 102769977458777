import type { IExternalIdentity } from '../../common/interfaces/externalIdentity';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests';
import type {
    DirectoryConnectionType,
    IDirectoryConnectionPayload,
} from './DirectoryConnectionService';

export const authenticationSettingUrl = `${process.buildConfigs.identityApiBaseRoute}/AuthenticationSetting`;

export const AuthenticationSettingType = {
    NoSetting: 'no setting',
    AAD: 'aad',
    Google: 'google',
    Microsoft: 'microsoft',
    SAML: 'saml2',
    AD: 'ad',
    LDAPAD: 'ldapad',
};

export const BulkAuthenticationSettingKey = {
    NoSetting: 'no setting',
    AAD: 'aad',
    Google: 'Google',
    Microsoft: 'Microsoft',
    SAML: 'Saml2',
    AD: 'ad',
    LDAPAD: 'ldapad',
};

export interface IAuthenticationSettingPayload {
    type: string;
    displayName: string;
    partitionGlobalId: string;
    externalIdentityProviderId?: number;
    allowedIdp?: string;
    clientId?: string;
    clientSecret?: string;
    tenantId?: string;
    settings?: string;
    department?: string;
    city?: string;
    extensionUserAttributeMappings?: Record<string, string>;
}

interface IAuthenticationSettingResponse {
    authenticationSettingType?: string;
    hostConnectionType?: DirectoryConnectionType;
    externalIdentityProviderDto: IExternalIdentity;
    directoryConnectionDto: IDirectoryConnectionPayload;
}

export interface IBulkAuthenticationSettingResponse {
    [key: string]: IAuthenticationSettingResponse;
}

export interface IAuthenticationIDPSettingPayload {
    allowedIdp: string;
    partitionGlobalId: string;
}

export function getTemplateData(payload: IAuthenticationSettingPayload) {
    return post<IAuthenticationSettingResponse>(`${authenticationSettingUrl}/GenerateTemplateSettings`, { body: authenticationSettingMapperSaml2(payload) });
}

export function getAllAuthenticationSettings({
    url: _url, partitionGlobalId,
}: { url: string; partitionGlobalId: string }) {
    return get<IBulkAuthenticationSettingResponse>(`${authenticationSettingUrl}/getAll/${partitionGlobalId}`);
}

export function updateIDPSetting(payload: IAuthenticationIDPSettingPayload) {
    return post<string>(`${authenticationSettingUrl}/IDP`, { body: payload });
}

export function deleteIDPSetting(partitionGlobalId: string) {
    return axiosDelete(`${authenticationSettingUrl}/IDP/${partitionGlobalId}`);
}

export function createDirectoryIntegrationSetting(payload: IAuthenticationSettingPayload) {
    return post<IAuthenticationSettingResponse>(`${authenticationSettingUrl}/DirectoryIntegration`, { body: authenticationSettingMapper(payload) });
}

export function updateDirectoryIntegrationSetting(payload: IAuthenticationSettingPayload) {
    return put<IAuthenticationSettingResponse>(`${authenticationSettingUrl}/DirectoryIntegration`, { body: authenticationSettingMapper(payload) });
}

export function createDirectoryIntegrationSettingSaml2(payload: IAuthenticationSettingPayload) {
    return post<IAuthenticationSettingResponse>(`${authenticationSettingUrl}/DirectoryIntegration`, { body: authenticationSettingMapperSaml2(payload) });
}

export function updateDirectoryIntegrationSettingSaml2(payload: IAuthenticationSettingPayload) {
    return put<IAuthenticationSettingResponse>(`${authenticationSettingUrl}/DirectoryIntegration`, { body: authenticationSettingMapperSaml2(payload) });
}

export function deleteDirectoryIntegrationSetting(partitionGlobalId: string) {
    return axiosDelete(`${authenticationSettingUrl}/DirectoryIntegration/${partitionGlobalId}`);
}

export function authenticationSettingMapper(payload: IAuthenticationSettingPayload) {
    const bodyPayload: any = {
        ...payload,
        authenticationScheme: 'oidc',
        isActive: true,
        isExclusive: true,
    };

    if (!bodyPayload.allowedIdp) {
        const tenantId = bodyPayload.tenantId.trim();
        bodyPayload.logoutUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout';
        bodyPayload.authority = `https://login.microsoftonline.com/${tenantId}/v2.0/`;
        bodyPayload.settings = JSON.stringify({ PostLogoutRedirectUriParameter: 'post_logout_redirect_uri' });
        bodyPayload.configuration = JSON.stringify({
            ClientId: payload.clientId,
            ClientSecret: payload.clientSecret,
            TenantId: tenantId,
            AADProvisioningAttributeMapper: {
                Department: payload.department,
                City: payload.city,
                ExtensionUserAttributeMappings: payload.extensionUserAttributeMappings,
            },
        });

        delete bodyPayload['tenantId'];
    }

    return bodyPayload;
}

export function authenticationSettingMapperSaml2(payload: IAuthenticationSettingPayload) {
    const bodyPayload: any = {
        ...payload,
        authenticationScheme: 'Saml2',
        isActive: true,
        isExclusive: true,
    };

    return bodyPayload;
}
